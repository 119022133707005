import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ButtonUp from "../../utilities/ButtomUp";
import Contact from "../../components/Contact";
import MenuItemsFiltered from "../../components/MenuItemsFiltered";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { HiArrowCircleUp } from "@react-icons/all-files/hi/HiArrowCircleUp";
import { IoCall } from "@react-icons/all-files/io5/IoCall";

const hover = "transition-all ease-in-out duration-300 hover:scale-[105%]";

const query = graphql`
  {
    allContentfulMenu(sort: { fields: image___createdAt }) {
      totalCount
      nodes {
        id
        title
        price
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`;

const DrinkPage = () => {
  useEffect(() => {
    let scroll;
    import("locomotive-scroll").then((locomotiveModule) => {
      scroll = new locomotiveModule.default({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
        smoothMobile: false,
        resetNativeScroll: true,
      });
    });

    return () => scroll.destroy();
  });
  useEffect(() => window.dispatchEvent(new Event("resize")), []);
  const {
    allContentfulMenu: { nodes: items, totalCount: total },
  } = useStaticQuery(query);
  return (
    <div
      data-scroll-container
      className="no-scrollbar relative overflow-hidden no-scrollbar flex flex-col justify-start items-center bg-gradient-to-b from-white to-stone-100 "
    >
      <div id="top2" />

      <div className="relative w-screen 2xl:w-new flex flex-col justify-center items-center">
        <div
          id="top"
          data-scroll
          data-scroll-speed="-1"
          data-scroll-position="top"
          data-scroll-direction="veritcal"
          className="lg:w-full lg:h-full flex justify-center items-center "
        >
          <Navbar link={"menu/drink#contact"} />
          <a
            href={`#contact`}
            data-scroll-to
            className={`${hover} z-50 absolute top-12 right-[7.2rem] bg-stone-800 hover:bg-blue-710 hover:shadow-lg rounded-full h-16 w-16 mx-5 drop-shadow-md shadow-md hidden lg:grid place-content-center`}
          >
            <IoCall className="text-white text-[1.8rem]" />
          </a>
        </div>

        <MenuItemsFiltered items={items} total={total} title={"Drinks"} />
        <Contact />

        <div
          id="top"
          data-scroll
          data-scroll-speed="0"
          data-scroll-position="bottom"
          data-scroll-direction="veritcal"
          className="relative w-full h-full flex justify-center items-center"
        >
          <Footer />
          <a
            href={`#top`}
            data-scroll-to
            className="absolute z-40 bottom-8 right-6 lg:right-16"
          >
            <button
              aria-label="up"
              className={`focus:outline-none z-50 bg-white h-12 w-12 md:h-14 md:w-14 rounded-full shadow-lg text-xl md:text-2xl text-green-110 hover:text-blue-710 hidden lg:flex justify-center items-center`}
            >
              <HiArrowCircleUp />
            </button>
          </a>
        </div>
        <div className="flex lg:hidden">
          <ButtonUp link={"menu/drink#top2"} />
        </div>
      </div>
    </div>
  );
};

export default DrinkPage;
